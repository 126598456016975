import React, { Component } from 'react';
import { Route, HashRouter } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
// import Home from './components/Home';
// import Order from './components/Order.';
// import About from './components/About';
import Dashboard from './components/dashboard/Dashboard';
import './App.css';

// AWS Amplify Auth Code
import { withAuthenticator } from '@aws-amplify/ui-react';
// import { AmplifySignOut } from '@aws-amplify/ui-react';

// AmplifySignOut button is added into the page
class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <div className="container">
            {/* <ul className="nav">
              <li><NavLink exact to="/">Home</NavLink></li>
              <li><NavLink to="/dashboard">Dashboard</NavLink></li>
              <li><NavLink to="/order">Order</NavLink></li>
              <li><NavLink to="/about">About</NavLink></li>
            </ul> */}
            <div className="pages">
              <Route exact path="/" component={Dashboard} />
              {/* <Route path="/dashboard" component={Dashboard} /> */}
              {/* <Route path="/order" component={Order} /> */}
              {/* <Route path="/about" component={About} /> */}
            </div>
          </div>
        </HashRouter>
        {/* <div>
          <AmplifySignOut />
          My App
        </div> */}
      </div>
    );
  }
}

// Amplify higher-order component (HoC) that wraps the whole APP
export default withAuthenticator(App);
// export default App;

