import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Import Amplify Config
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsmobile.aws_project_region,
    userPoolId: awsmobile.aws_user_pools_id,
    identityPoolId: awsmobile.aws_cognito_identity_pool_id,
    userPoolWebClientId: awsmobile.aws_user_pools_web_client_id
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
