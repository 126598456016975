import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Table, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Title from './Title';

import { Auth } from 'aws-amplify';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
    return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
    createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
    createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
    createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
    createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
    createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

function convertDatetime(time) {
    let newT = new Date(time + 'Z');
    let year = newT.getFullYear()
    let month = (newT.getMonth() + 1).toString().padStart(2, "0")
    let date = (newT.getDate()).toString().padStart(2, "0")
    let hour = (newT.getHours()).toString().padStart(2, "0")
    let minute = (newT.getMinutes()).toString().padStart(2, "0")
    let second = (newT.getSeconds()).toString().padStart(2, "0")
    let strTime = year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
    return strTime
}

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function Orders() {
    const classes = useStyles();
    //Create a state to store Order File History from API
    const [orderFileHistory, setOrderFileHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    //Get order file history from API
    const getExportHistory = async () => {

        Auth.currentAuthenticatedUser().then(res => {
            const jwt = res.getSignInUserSession().getAccessToken().getJwtToken()
            fetch(
                'https://5sj9j9sqq4.execute-api.ap-southeast-2.amazonaws.com/order-files',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': jwt
                    }
                })
                .then(res => res.json())
                .then(response => {
                    setOrderFileHistory(response);
                    setIsLoading(false);
                })
                .catch(error => console.log(error));
        })
    }

    const newRequest = async () => {
        setIsLoading(true);
        Auth.currentAuthenticatedUser().then(res => {
            const jwt = res.getSignInUserSession().getAccessToken().getJwtToken()
            fetch(
                'https://5sj9j9sqq4.execute-api.ap-southeast-2.amazonaws.com/order-file-request',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': jwt
                    }
                })
                .then(res => res.json())
                .then(response => {
                    setIsLoading(false);
                    getExportHistory();
                })
                .catch(error => console.log(error));
        })
    }



    useEffect(() => {
        getExportHistory();
    }, []);



    return (
        <React.Fragment>
            {
                isLoading
                &&
                <Alert severity="success">
                    Request Received. Order files will email out soon!!
                </Alert>
            }
            <Title>Request Order Files</Title>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        {/* <TableCell>Orders Export Between</TableCell> */}
                        <TableCell align="left">Request Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderFileHistory.map((row, index) => (
                        <TableRow key={index} selected={!index}>
                            <TableCell>{index+1}</TableCell>
                            {/* <TableCell>{row.request_start_time} - {row.request_end_time}</TableCell> */}
                            <TableCell align="left">{convertDatetime(row.timestamp)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button
                variant="contained" color="secondary"
                onClick={e =>
                    window.confirm("Are you sure to Request Order File?") &&
                    newRequest()
                }
            >Send latest order files
            </Button>
            <br />
            <br />
            <br />
            <br />
            <Title>Orders Not Shipped</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Store</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Ship To</TableCell>
                        <TableCell align="right">Sale Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.store}</TableCell>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.orderId}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.shipTo}</TableCell>
                            <TableCell align="right">{row.amount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    See more orders
                </Link>
            </div>
        </React.Fragment>
    );
}